
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const contactsAgreement = ref(false)
    const numbersAgreement = ref(false)

    return {
      contactsAgreement,
      numbersAgreement,
    }
  },
})
